body {
  background-color: #f5f5f5;
}

td {
  max-width: 645px;
  overflow: hidden;
  box-sizing: border-box;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
